body {
  font-family: Lato !important;
}
@font-face {
  font-family: "Lato";
  src: local("Lato"), url(../fonts/Lato/Lato-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Lato";
  font-weight: 900;
  src: local("Lato"), url(../fonts/Lato/Lato-Bold.ttf) format("opentype");
}

.logo img {
  position: absolute;
  max-height: 100%;
  max-width: 94%;
  left: -5px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 74px;
  position: relative;
}
.rhInfo {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.delito {
  font-size: 13px;
  cursor: pointer;
  font-weight: 700;
  color: #666666;
  line-height: 14.4px;
}
.loctxt {
  color: #e12e2a !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 16.8px !important;
}
.searchBox {
  height: 50px;
  font-weight: 400 !important;
  padding-left: 33px !important;
  background-color: #f8f8f8 !important;
  border-radius: 5px !important;
}
.search-icon {
  position: absolute;
  left: 13.5px;
  top: 16px;
  z-index: 9;
  font-size: 14px;
  color: #949494;
  font-weight: 400;
  text-align: center;
}
.headerMain {
  background-color: #fff !important;
}
.profileIcon {
  font-weight: 400 !important;
}
button.ULText {
  color: #363636;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  width: 100px;
  margin-left: 5px;
}
button.ULText:hover {
  color: #363636;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
}
.userDropDown button {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #363636;
}
.userDropDown button:hover {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #363636;
}
.userDropDown button.btn.show {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #777575;
}
.hpriceTxt {
  font-size: 14px;
  font-weight: 800;
  line-height: 19.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 0.5rem;
  margin-bottom: -0.3rem !important;
}
.hitemTxt {
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.ciDiv svg {
  margin-right: 5px;
}
.chRow .navbar {
  background: #f7f3f3;
  border-bottom: 1px solid #7a7a7a0f;
  /* box-shadow: 0px 0px 0px 0px #7a7a7a0f;
    box-shadow: 0px 1px 3px 0px #7a7a7a0f; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.chRow.sticky {
  top: 72px;
}
.category.btn {
  font-size: 13.5px !important;
  font-weight: 800 !important;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #666666 !important;
}
.text-theme-red {
  color: #e12e2a !important;
}
.text-theme-green {
  color: #80b83a !important;
}
.Hmonthlisttxt span,
.Hmonthlisttxt svg {
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.Hnavlink a {
  padding: 0 !important;
  line-height: 33px;
}
.App-header {
  background: #e12e2a;
}

.BICarousel img {
  height: 100%;
}

.dealsTxt {
  font-size: 22px;
  font-weight: 700;
  line-height: 26.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #363636;
  padding-left: 0 !important;
}

.home-brand .card {
  height: 200px;
}
.PCard .product-img {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.price-section {
  font-size: 22px;
  font-weight: 700;
  line-height: 26.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.mrp-section {
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.product-name-section {
  font-size: 14px;
  font-weight: 600;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  max-width: 60%;
  color: #363636;
  text-transform: capitalize;
}
.brand-section {
  font-size: 12px !important;
  font-weight: 600;
  line-height: 14.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #666666;
}

.ofTitle {
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.getAppDiv {
  margin-top: 12% !important;
}
.variantInput {
  height: 35px;
}
.variantInput .input-group-prepend .input-group-text {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.variantInput .input-group-append .input-group-text {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.variantInput .form-control {
  max-width: 33px;
}
.variantInput input,
.variantInput .input-group-text {
  height: 35px;
  border-radius: 0px !important;
}

.product-section {
  font-size: 12px !important;
  font-weight: 400;
  height: 35px;
}
.addcartBtn {
  height: 35px;
}

.half.col,
.half.col .card-body {
  height: 186px !important;
}
.half .PCard {
  flex-direction: row !important;
  height: 185px;
  margin: 5px;
}
.half .proImage {
  height: 175px;
}
.color-primary {
  color: #e12e2a !important;
}

.carousel-inner {
  border-radius: 30px;
}

.carousel {
  padding: 0 !important;
}

.mrp-section {
  font-size: 13px;
}

.price-section {
  font-size: 22px;
}

.brand-section {
  max-width: 40%;
  font-size: 14px;
}

.brand-img {
  height: 90%;
  width: 80%;
}

.footer-info .list-group-item {
  border: none;
}
.dropdown-menu.show {
  padding: 10px;
}
.dropend.btn-group {
  padding: 1px;
}
.show.dropdown .category:hover {
  color: #e12e2a !important;
}
.show.dropend .category:hover {
  color: #e12e2a !important;
}
.show.dropdown-menu .dropdown-item:hover {
  color: #e12e2a !important;
}
.dropdown-item {
  font-size: 13.5px !important;
  font-weight: 800 !important;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #666666 !important;
}
.dropend.btn-group {
  border-bottom: 1px solid #ffc5c538;
  border-radius: 1px !important;
  padding: 10px;
}
.dropend.btn-group:last-child,
.dropdown-menu.show .dropdown-item:last-child {
  border-bottom: 0px !important;
}
.dropdown-menu.show {
  width: 200px;
  border: 1px solid #ccc !important;
}
.dropdown-menu.show .dropdown-item {
  border-bottom: 1px solid #ffc5c538;
  border-radius: 1px !important;
  padding: 6px;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent !important;
}

/* Cart PAge design */

.cartpage .breadcrumb {
  position: relative;
  top: 50px;
}

.cartpage .breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">");
}
.cartpage .activeBC {
  font-size: 13px;
  font-weight: 500;
  line-height: 13.86px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #e12e2a !important;
}
.cartpage .BCHome {
  margin-top: -2px;
  margin-right: 0px;
}
.cartpage .Cheadertxt {
  font-size: 28px;
  font-weight: 800;
  line-height: 33.6px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.cartpage .CmainBC {
  border-top: 1px solid #eeeded;
  border-bottom: 1px solid #eeeded;
  padding: 1rem;
}
.cartpage .cartTable th {
  font-size: 15px;
  font-weight: 500;
  line-height: 21.6px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #363636;
}
.cartpage .cartTable .cartTxt {
  font-size: 12px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: 0.04em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #555;
}
.cartpage .Cvarient .input-group-prepend .input-group-text {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.cartpage .Cvarient .input-group-append .input-group-text {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.cartpage .form-control {
  max-width: 50px;
  background-color: #f4f0f0 !important;
}

.cartpage .cartTable .input-group-text {
  height: 30px;
  border-radius: 0px !important;
  border: none !important;
  background-color: #f4f0f0;
  color: #666666;
}
.cartpage .cartTable .Cvarient input {
  height: 30px;
  border: none !important;
  color: #343c44;
}
.cartpage .CTimg {
  height: 55px;
}
.cartpage .cartTable tbody tr td {
  padding: 15px !important;
  justify-content: center;
}

.cartpage .cartTable tbody tr:last-child {
  border-bottom: 1px solid #faf5f5 !important;
}

.cartpage .cartTable .cartPrice {
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 24px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #e12e2a !important;
  align-content: center;
  margin-top: 5px;
}
.cartpage .list-group-item {
  border: none !important;
  background-color: #f8f8f8;
  font-family: Lato;
  font-size: 13.5px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #666666;
}
.cartpage .card .card-body .card-title {
  font-size: 17px;
  font-weight: 700;
  line-height: 21.6px;
  letter-spacing: 0.04em;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding-bottom: 5px;
  color: #555 !important;
}
.cartpage .summary.card {
  background-color: #f8f8f8;
  border: none !important;
}
.cartpage .Sumrybody {
  border-top: 1px solid #e7e7e7;
  padding: 6px 0px 2px 0px;
  border-radius: 0px !important;
}
.cartpage .card-footer {
  border-top: 1px solid #e7e7e7 !important;
  background-color: transparent !important;
  font-family: Lato;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #555 !important;
  padding: 16px;
}
#shipping-address-tabs-tabpane-home h5 {
  font-size: 17px;
  font-weight: 700;
  line-height: 21.6px;
  letter-spacing: 0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #363636;
}
.active-address.card {
  border: 1px solid #e12e2a;
}
.active-address .form-check-input:checked {
  background-color: #e12e2a;
  border-radius: 25px !important;
  height: 15px;
}
.txtCard {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #363636 !important;
  min-height: 150px;
}

.p_banner:nth-child(3n + 1) {
  padding-left: 0;
}

.p_banner:nth-child(3n) {
  padding-right: 0;
}

#basic-addon2,
#phone-number {
  border-radius: 30px;
}

#basic-addon2 {
  z-index: 10;
  font-weight: bold;
  color: #fff;
  background-color: #80b83f;
}

.footer-info .list-group-item {
  padding-left: 0;
  border-bottom: 0;
  border-top: 0;
}

.category-menu a {
  font-size: 14px;
}

.previous-purchase {
  background: red;
}

.breadcrumb {
  font-size: 12px !important;
}

.product-filters {
  background-color: transparent !important;
  color: #000 !important;
  border-color: #000 !important;
}

/*.fa-instagram {
    color: transparent;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    background-clip: text;
    -webkit-background-clip: text;
}*/

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.sticky {
  position: fixed;
  top: 0;
  left: 11px;
  width: 100%;
  z-index: 10;
  animation-duration: 0.7s;
  animation-name: fadeInDown;
  animation-timing-function: ease-out;
}

.list-group-item {
  background-color: transparent !important;
}

.scroller {
  height: 18px;
  line-height: 18px;
  position: relative;
  overflow: hidden;
  text-align: left;
}

.scroller > span {
  position: absolute;
  top: 0;
  animation: slide 2s ease-in-out infinite;
}

@keyframes slide {
  0% {
    top: 0;
  }

  /*  50% {
          
        }*/
  100% {
    top: -1.3em;
  }
}

.formText {
  font-size: 13px !important;
  padding: 13px !important;
}

.formText:focus {
  box-shadow: none !important;
  border-color: #e12e2a !important;
}

.btn {
  background-color: #e12e2a !important;
  border-color: #e12e2a !important;
}


.btn-warning {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

.btn-cancel{
    background-color: #7b7979 !important;
  border-color: #7b7979 !important;
}

input::placeholder {
  /*color:#ccc !important;*/
  opacity: 0.5 !important;
  /* Firefox */
}

.btn-bg-none {
  background-color: transparent !important;
  border-color: transparent !important;
}

.resend-text {
  font-size: 12px;
  color: #bababa;
}

/* .loleft-img {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
  margin-left: -1px !important;
} */

.login-change-text {
  color: #e12e2a !important;
  font-size: 12px !important;
  top: 12px;
}

.login-model-footer {
  background-color: #fafafa !important;
}

.login-model-footer-title {
  font-size: 14px;
  color: #666666;
}

.login-model-footer-content {
  font-size: 12px;
  color: #bababa;
}

.product-img {
  display: flex;
  max-width: max-content !important;
  height: 148px;
  margin: auto;
}

/* Cart Button Design */

.cart-product-quantity {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 130px;
  max-width: 100%;
  border: 0.5px solid rgb(177, 177, 177);
  border-radius: 5px;
}

.cart-product-quantity button {
  border: none;
  outline: none;
  background: none;
  padding: 0.7rem 1.5rem;
  cursor: pointer;
}

.cart-product-quantity .count {
  padding: 0.7rem 0;
}

.cursor-pointer {
  cursor: pointer;
}

.cart-h-icon {
  background-color: #ccc;
  padding: 5px 8px;
  border-radius: 50%;
}

.active-c .cart-h-icon {
  background-color: #e12e2a;
}

.active-c {
  /* background: #f7f3f3; */
}

.shipping-address {
  color: #fff;
}

#shipping-address-tabs.nav-tabs .nav-item .nav-link {
  color: #000;
}

#shipping-address-tabs.nav-tabs .nav-item.show .nav-link,
#shipping-address-tabs.nav-tabs .nav-link.active {
  border-color: #fff;
  font-weight: bold;
  border-bottom: 3px solid #e12e2a !important;
  color: #e12e2a !important;
}

#shipping-address-tabs.nav-tabs .nav-link:focus,
#shipping-address-tabs.nav-tabs .nav-link:hover {
  border-color: #fff;
}

.checkout-sa-card {
  border: 0 !important;
}

.active-address {
  border-color: #e12e2a !important;
}

.active-address .form-check-input:checked {
  background-color: #e12e2a;
  border-color: #e12e2a;
}

.active-address .address-name {
  color: #e12e2a;
  font-weight: bold;
}

.card .form-check-input:focus {
  box-shadow: none;
}

.h-b.dropdown-item.active,
.h-b.dropdown-item:active {
  background-color: transparent;
  color: #000;
}

.profile-header-row {
  font-size: 15px;
}

.order-card .placed-date {
  font-size: 13px;
}

.myorders-img {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  position: relative;
}

.myorders-more {
  border: 1px solid #ccc;
  border-radius: 50%;
  font-size: 12px;
  position: relative;
  right: 0px;
  z-index: 0;
  width: 29px;
  height: 29px;
  float: right;
  text-align: center;
  line-height: 29px;
}

.myorder-img-sec {
  position: relative;
  height: 30px;
}

.myorders-img:first-child {
  right: -15px;
  z-index: 3;
}

.myorders-img:nth-child(2) {
  right: -10px;
  z-index: 2;
}

.myorders-img:nth-child(3) {
  right: -5px;
  z-index: 1;
}

/* liveGmapStyles */

.pac-container {
  z-index: 1056 !important;
}
.location-searchbox {
  height: 50px;
}
.location-searchbox:hover,
.location-searchbox:active {
  border: 1px solid #e12e2a;
}
.location-searchbox:focus {
  border-color: #f6afad;
  box-shadow: 0 0 0 0.25rem rgba(238, 102, 99, 0.5);
}
.get-location-btn {
  display: flex;
  align-items: center;
  background-color: #e12e2a !important;
  border: 1px solid #dc4646 !important;
  border-radius: 8px !important;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  justify-content: start;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  height: 50px;
}

.get-location-btn:hover {
  background-color: #e12e2a !important;
  /* color: #E12E2A; */
}

.location-icon {
  /* position: absolute;
  top: 84px;
  left: 30px;
  font-size: 25px; */
  color: #fff;
}

.btn-content {
  display: flex;
  flex-direction: column;
}

.btn-content span {
  font-weight: bold;
  color: #333;
}

.btn-content small {
  font-size: 12px;
  color: #888;
}

.saved-addresses {
  /* border: 1px solid lightgrey; */
  padding: 10px;
  border-radius: 10px;
}

.saved-addresses .address-item {
  padding-bottom: 15px;
  /* border-bottom: 1px dashed #ccc; */
}

.saved-addresses .address-item:hover,
.saved-addresses .address-item.active {
  color: #e12e2a;
  cursor: pointer;
}

/* serviceUnserviceable style  */

.unserviceable-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;
  margin-top: 100px;
}

.unserviceable-image-container {
  margin-bottom: 20px;
}

.unserviceable-image {
  width: 200px;
  /* Adjust size as needed */
  height: auto;
}

.unserviceable-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.unserviceable-description {
  font-size: 16px;
  color: #666;
}

.variantClass .btn {
  background-color: transparent !important;
  border-radius: 0.375rem !important;
  color: #000;
}
.variantClass .btn.active {
  background-color: #e12e2a !important;
  color: #fff !important;
}

.td-none a,
button a {
  text-decoration: none;
  color: inherit;
}
input[type="checkbox"] {
  cursor: pointer;
}
.parent-cat{
  background: #f8f9fa;
}

.category {
  background-color: transparent !important;
  color: black !important;
  border: none !important;
  padding: 8px !important;
  font-weight: bold !important;
  font-size: 13px !important;
}

.dropend.btn-group {
  width: 100%;
}

.offer-badge {
  font-size: 15px !important;
  height: 60px;
  width: 60px;
  padding: 0 !important;
  align-items: center !important;
  text-align: center !important;
  line-height: 56px !important;
}

.filter-brand-section {
  height: 315px;
  overflow: auto;
}
.qr-image {
  width: 25%;
  margin: auto;
}
.profile-section-icon {
  line-height: 48px;
}

.df-addr .card {
  border-color: #e12e2a;
  background: #f8f9fa;
}
.appContent {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    184deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(241, 234, 234, 1) 35%
  );
  border-radius: 5px;
}
.addressCornor {
  position: absolute;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 50px !important;
  height: 50px !important;
  background-color: #333;
}
.addr-type-icon {
  font-size: 20px;
  color: #e12e2a;
}
.order-success-icon
{
    font-size: 90px !important;
}

@media (max-width: 576px) {
  .carousel-inner {
    border-radius: 10px;
  }
  .dealsTxt {
    margin-top: 3px;
  }
  .p_banner {
    margin-top: 5px !important;
  }
  .p_banner:nth-child(odd) {
    padding-left: 0;
    padding-right: 5px;
  }

  .p_banner:nth-child(even) {
    padding: 0;
    padding-left: 5px;
  }

  .half.col,
  .half.col .card-body {
    height: 140px !important;
  }

  .half .proImage {
    height: 140px;
  }
  .half .PCard .product-img {
    margin: 0;
    max-height: 100%;
    height: 100%;
  }
  .half .PCard {
    height: 140px;
  }
  .half .product-name,
  .half .addcartBtn {
    width: 100% !important;
  }
  .home-brand .card {
    height: 150px;
  }
  .col .card {
    height: 200px;
  }
  .getAppDiv {
    margin-top: 10px !important;
  }
  .qr-image {
    width: 30%;
    margin: auto;
  }
  .logo img {
    height: 50px;
  }
  button.ULText {
    margin-left: 0;
  }
  .ciDiv svg {
    margin-right: 0;
  }
  .searchBox {
    height: 40px;
  }
  .search-icon {
    top: 10px;
  }
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
  .cartpage .breadcrumb {
    top: 0;
  }
  .cartpage .CmainBC {
    padding: 0;
  }
  .cartTable {
    width: 200% !important;
  }
  .double-slider-box{
      max-width: 400px !important;
      margin-top: 35px !important;
  }
  .pd-img-thumb
  {
      width:100px;
  }
}



/* Container for the image */
.image-magnifier-container {
  position: relative;
  overflow: hidden; /* Hide the zoomed-in image outside the container */
  cursor: zoom-in; /* Indicate to users that zoom is available */
}

/* Magnifying glass effect */
.magnifying-glass {
  position: absolute;
  border: 3px solid #fff;
  width: 100px; /* Size of the magnifying glass */
  height: 100px; /* Size of the magnifying glass */
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 200%; /* Increase this number to zoom more */
  pointer-events: none; /* Disable interactions with the magnifying glass itself */
  opacity: 0; /* Initially invisible */
  transition: opacity 0.3s ease;
}

/* Show the magnifying glass when hovering over the image */
.image-magnifier-container:hover .magnifying-glass {
  opacity: 1;
}

.image-magnifier-container:hover {
  cursor: zoom-out; /* Indicate that the zoom effect can be undone */
}
