.double-slider-box {
    background-color: #fff;
    border-radius: 10px;
    padding: 0 20px;
    width: 100%;
    max-width: 300px;
    margin: auto;
    float:left;
}

.range-slider {
    position: relative;
    width: 100%;
    height: 5px;
    margin: 15px 0;
    background-color: #8a8a8a;
    border-radius: 5px;
}

.slider-track {
    height: 100%;
    position: absolute;
    background-color: #fe696a;
    left: 0;
    right: 100%;
    border-radius: 5px;
}

.range-slider input[type="range"] {
    position: absolute;
    width: 100%;
    top: 0;
    transform: translateY(-50%);
    background: none;
    pointer-events: none;
    appearance: none;
    height: 5px;
}

input[type="range"]::-webkit-slider-thumb {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 3px solid #fff;
    background: #fe696a;
    pointer-events: auto;
    appearance: none;
    cursor: pointer;
    box-shadow: 0 0.125rem 0.5625rem -0.125rem rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 2; /* Ensure thumbs appear above the track */
}

input[type="range"]::-moz-range-thumb {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 3px solid #fff;
    background: #fe696a;
    pointer-events: auto;
    cursor: pointer;
    box-shadow: 0 0.125rem 0.5625rem -0.125rem rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 2;
}

.input-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.min-box,
.max-box {
    width: 50%;
}

.min-box {
    margin-right: 10px;
}
.max-box input {
    float: right;
}
input[type="number"] {
    width: 80px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
}

.min-tooltip,
.max-tooltip {
    position: absolute;
    top: -40px;
    font-size: 12px;
    color: #555;
    background-color: #fff;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 1;
}

.min-tooltip {
    left: 0;
    transform: translateX(-50%);
}

.max-tooltip {
    right: 0;
    transform: translateX(50%);
}
/* Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
